import classNames from 'classnames';
import PropTypes from 'prop-types';
import Link from '@components/Link';

import Styles from './Blog.module.scss';

const Nav = ({ className }) => {
  return (
    <div className={Styles.navContainer}>
      <div className={Styles.nav}>
        <Link
          className={classNames(Styles.navLink)}
          activeClassName={Styles.navActive}
          title='Home'
          to='/blog'>
          {`Home`}
        </Link>
        <Link
          className={classNames(Styles.navLink)}
          activeClassName={Styles.navActive}
          title='Leaders'
          to='/blog/categories/leaders'>
          {`Leaders`}
        </Link>
        <Link
          className={classNames(Styles.navLink)}
          activeClassName={Styles.navActive}
          title='Digital'
          to='/blog/categories/digital'>
          {`Digital`}
        </Link>
        <Link
          className={classNames(Styles.navLink)}
          activeClassName={Styles.navActive}
          title='Storytelling'
          to='/blog/categories/storytelling'>
          {`Storytelling`}
        </Link>
        <Link
          className={classNames(Styles.navLink)}
          activeClassName={Styles.navActive}
          title='Hiring Resources'
          to='/blog/categories/hiring-resources'>
          {`Hiring`}
        </Link>
        {/*<Link
          className={classNames(Styles.navLink)}
          activeClassName={Styles.navActive}
          title={`Hire Digital Updates`}
          to='/blog/categories/contentco-updates'>
          {`Updates`}
        </Link>*/}
      </div>
    </div>
  );
};

Nav.propTypes = {
  className: PropTypes.string,
};

export default Nav;
