import { useState } from 'react';
import PropTypes from 'prop-types';
import dynamic from 'next/dynamic';

import Nav from '@components/Blog/Nav';
import LatestPost from '@components/Blog/LatestPost';
import Layout from '@components/Layout/Layout';
import LoadingArea from '@hiredigital/ui/LoadingArea';
import Header from '@components/Header/InsightsHeader';

import { getStories } from '@helpers/posts';
import Styles from './List.module.scss';

const BlogStories = dynamic(() => import('@components/PageComponents/BlogStories'), {
  loading: () => <LoadingArea height={1594} />,
  ssr: false,
});
const Subscribe = dynamic(() => import('@components/Subscribe/BlogSubscribe'), { ssr: false });

const Blog = ({ postData, metaData }) => {
  const [posts, setPosts] = useState(postData);
  const latestPost = postData[0]?.node;
  const [meta, setMeta] = useState(metaData);
  const [loading, setLoading] = useState(false);

  const head = {
    title: `Insights | Hire Digital`,
    meta: [
      {
        name: 'description',
        content: `Learn how to stay ahead of marketing, design, and software development trends with insights from the experts at Hire Digital.`,
      },
    ],
  };

  const addMore = async () => {
    try {
      setLoading(true);
      const data = await getStories(meta.endCursor);
      const newMeta = data?.pageInfo;
      const newPosts = data?.edges;

      setPosts([...posts, ...newPosts]);
      setMeta(newMeta);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Layout
      header={Header}
      pageHead={head}
      variant={Layout.Variants.INSIGHTS}
      showHeaderBackground
      hasHero={false}>
      <Nav />
      <LatestPost latestPost={latestPost} />
      <BlogStories
        className={Styles.container}
        posts={posts}
        hasNext={meta?.hasNextPage}
        isLoading={loading}
        onLoadMore={addMore}
        start={1}
      />
      <Subscribe />
    </Layout>
  );
};

Blog.propTypes = {
  postData: PropTypes.array,
  metaData: PropTypes.object,
};

export default Blog;

export const getStaticProps = async () => {
  // TODO Refactor
  const data = await getStories();
  // const postSlugs = posts.map(({ slug }) => slug);
  const metaData = data?.pageInfo;
  const postData = data?.edges.map((p, i) => {
    // hydration data reduction
    if (i > 0) {
      // delete p.node.tags;
      p.node.tags.nodes.slice(0, 1);
      delete p.node.id;
      return p;
    }
    return p;
  });
  return {
    props: { postData, metaData },
    revalidate: 7200, // In seconds
  };
};
